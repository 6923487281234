<template>
  <validation-observer ref="WebSettings">
    <b-row>
      <b-col
        md="3"
      >
        <b-card>
          <validation-provider
            #default="{ errors }"
            name="Название"
            rules="required"
          >
            <b-form-group
              label="Название"
              label-for="title"
            >
              <b-form-input
                id="title"
                v-model="settings.title"
                placeholder="Название"
              />

              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
          <h4 class=" mt-4 mb-1">
            Логотип:
          </h4>
          <div class="text-center">
            <b-img
              v-if="logo"
              class="mb-1"
              thumbnail
              fluid
              :src="logo"
              alt="Логотип"
            />
            <div
              v-if="!logo"
              class="mb-1"
              style="border: 1px dashed #ccc; padding: 74px; border-radius: 5px; "
            >
              Нет Фото
            </div>
          </div>
          <validation-provider
            #default="{ errors }"
            name="Логотип"
            rules="required"
          >
            <b-form-file
              v-model="logoImg"
              accept=".jpg, .png, .jfif, .ico"
              :browse-text="$t('Загрузить')"
              :placeholder="$t('Виберите файл')"
              :drop-placeholder="$t('Виберите файл')"
              @input="logoToBase"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-card>
      </b-col>
      <b-col>
        <b-card>
          <h4 class=" mb-1">
            Баннер:
          </h4>
          <div class=" w-100 text-center">
            <b-img
              v-if="banner"
              class="mb-1 w-100"
              thumbnail
              fluid
              :src="banner"
              alt="Баннер"
            />
            <div
              v-if="!banner"
              class="mb-1"
              style="border: 1px dashed #ccc; padding: 300px; border-radius: 5px; "
            >
              Нет Фото
            </div>
          </div>
          <validation-provider
            #default="{ errors }"
            name="Баннер"
            rules="required"
          >
            <b-form-file
              v-model="bannerImg"
              accept=".jpg, .png"
              :browse-text="$t('Загрузить')"
              :placeholder="$t('Виберите файл')"
              :drop-placeholder="$t('Виберите файл')"
              @input="bannerToBase"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-card>
      </b-col>
      <b-col
        cols="12"
        class="d-flex justify-content-end mt-2"
      >
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="success"
          @click="save()"
        >
          Сохранить
        </b-button>
      </b-col>
    </b-row>
  </validation-observer>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BCard,
  BFormInput,
  BFormGroup,
  BFormFile,
  BImg,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'
import formatter from '@/mixins/formmatter'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BCard,
    BFormInput,
    BFormGroup,
    BFormFile,
    BImg,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  mixins: [formatter],
  data() {
    return {
      required,
      logoImg: null,
      bannerImg: null,
      baseLogo: null,
      baseBanner: null,
      settings: {
        title: '',
        logo: null,
        banner: null,
      },
    }
  },
  computed: {
    logo() {
      return (
        this.baseLogo
          || this.settings.logo
          || null
      )
    },
    banner() {
      return (
        this.baseBanner
          || this.settings.banner
          || null
      )
    },
  },
  methods: {
    bannerToBase() {
      this.$_convertToBase64(this.bannerImg).then(res => {
        this.baseBanner = res
      })
    },
    logoToBase() {
      this.$_convertToBase64(this.logoImg).then(res => {
        this.baseLogo = res
      })
    },

    save() {
      this.$refs.WebSettings.validate().then(async success => {
        console.log(success)
      })
    },
  },
}
</script>

<style>

</style>
